import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SubTab = ({ category }) => {
  console.log(category);
  const [tab, setTab] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // category 값에 따라 초기 탭을 설정합니다.
    switch (category) {
      /* 산학융합지구조성사업 */
      case "fusionsupportprogram":
        setTab("fusionsupportprogram");
        break;
      /* 충남지역혁신프로젝트 사업 */
      case "chungnamInnovationProject":
        setTab("chungnamInnovationProject");
        break;
      /* 충남지역혁신클러스터 */
      case "ChungnamInnovationCluster":
        setTab("ChungnamInnovationCluster");
        break;
      /* 탄소중립실증인프라구축사업 */
      case "CarbonNeutralInfrastructure":
        setTab("CarbonNeutralInfrastructure");
        break;

      /* 지역산업맞춤형 인력양성사업 */
      case "localIndustryTalentDevelopment":
        setTab("localIndustryTalentDevelopment");
        break;
      /* 한국형 퀵스타트프로그램 사업 */
      case "koreanQuickStartProgram":
        setTab("koreanQuickStartProgram");
        break;
      /* 중소기업 연구인력 현장맞춤형 양성지원사업 */
      case "smeResearchTalentSupport":
        setTab("smeResearchTalentSupport");
        break;
      /* 충남산학융합형 인력양성사업 */
      case "chungnamIndustryAcademiaProgram":
        setTab("chungnamIndustryAcademiaProgram");
        break;
      /* 청년도전지원사업 */
      case "youthVentureProgram":
        setTab("youthVentureProgram");
        break;

      /* 당진시 창업지원사업 */
      case "dangjinStartupSupport":
        setTab("dangjinStartupSupport");
        break;
      /* 희망리턴패키지 사업 */
      case "hopeReturnPackage":
        setTab("hopeReturnPackage");
        break;
      /* 홍성군 잇슈창고운영사업 */
      case "hongseongIssueWarehouse":
        setTab("hongseongIssueWarehouse");
        break;

      default:
        setTab("chungnamInnovationProject");
    }
    // console.log(`현재 선택된 카테고리: ${category}`);
  }, [category]);

  const pageTab = (num) => {
    setTab(num);
    if (category === "fusionsupportprogram") {
      navigate(`/${num}`);
    } else {
      navigate(`/business/${num}`);
    }
  };

  const renderTabContent = () => {
    switch (tab) {
      case "fusionsupportprogram":
        return (
          <Fragment>
            <div
              className={`subtab-s1 ${
                tab === "fusionsupportprogram" ? "active" : ""
              }`}
              onClick={() => pageTab("fusionsupportprogram")}
            >
              산학융합지구조성사업
            </div>
          </Fragment>
        );
      case "chungnamInnovationProject":
      case "ChungnamInnovationCluster":
      case "CarbonNeutralInfrastructure":
        return (
          <Fragment>
            <div
              className={`subtab-s1 ${
                tab === "chungnamInnovationProject" ? "active" : ""
              }`}
              onClick={() => pageTab("chungnamInnovationProject")}
            >
              혁신프로젝트사업
            </div>
            <div
              className={`subtab-s1 ${
                tab === "ChungnamInnovationCluster" ? "active" : ""
              }`}
              onClick={() => pageTab("ChungnamInnovationCluster")}
            >
              혁신클러스터
            </div>
            <div
              className={`subtab-s1 ${
                tab === "CarbonNeutralInfrastructure" ? "active" : ""
              }`}
              onClick={() => pageTab("CarbonNeutralInfrastructure")}
            >
              탄소중립실증 인프라구축사업
            </div>
          </Fragment>
        );
      case "localIndustryTalentDevelopment":
      case "koreanQuickStartProgram":
      case "smeResearchTalentSupport":
      case "chungnamIndustryAcademiaProgram":
      case "youthVentureProgram":
        return (
          <Fragment>
            <div
              className={`subtab-s1 ${
                tab === "localIndustryTalentDevelopment" ? "active" : ""
              }`}
              onClick={() => pageTab("localIndustryTalentDevelopment")}
            >
              지역산업맞춤형 인력양성사업
            </div>
            <div
              className={`subtab-s1 ${
                tab === "koreanQuickStartProgram" ? "active" : ""
              }`}
              onClick={() => pageTab("koreanQuickStartProgram")}
            >
              한국형 퀵스타트프로그램 사업
            </div>
            <div
              className={`subtab-s1 ${
                tab === "smeResearchTalentSupport" ? "active" : ""
              }`}
              onClick={() => pageTab("smeResearchTalentSupport")}
            >
              중소기업 연구인력 현장맞춤형 양성지원사업
            </div>
            <div
              className={`subtab-s1 ${
                tab === "chungnamIndustryAcademiaProgram" ? "active" : ""
              }`}
              onClick={() => pageTab("chungnamIndustryAcademiaProgram")}
            >
              충남산학융합형 인력양성사업
            </div>
            <div
              className={`subtab-s1 ${
                tab === "youthVentureProgram" ? "active" : ""
              }`}
              onClick={() => pageTab("youthVentureProgram")}
            >
              청년도전지원사업
            </div>
          </Fragment>
        );
      case "dangjinStartupSupport":
      case "hopeReturnPackage":
      case "hongseongIssueWarehouse":
        return (
          <Fragment>
            <div
              className={`subtab-s1 ${
                tab === "dangjinStartupSupport" ? "active" : ""
              }`}
              onClick={() => pageTab("dangjinStartupSupport")}
            >
              당진시 창업지원사업
            </div>
            <div
              className={`subtab-s1 ${
                tab === "hopeReturnPackage" ? "active" : ""
              }`}
              onClick={() => pageTab("hopeReturnPackage")}
            >
              희망리턴패키지 사업
            </div>
            <div
              className={`subtab-s1 ${
                tab === "hongseongIssueWarehouse" ? "active" : ""
              }`}
              onClick={() => pageTab("hongseongIssueWarehouse")}
            >
              홍성군 잇슈창고운영사업
            </div>
          </Fragment>
        );
      default:
        return <div>탭이 선택되지 않았습니다.</div>;
    }
  };

  return (
    <div className="subtab-wrap">
      <div className="subtab-content-box">
        <div className="subtab-background">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default SubTab;
