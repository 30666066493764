import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/chungnamInnovationProject_img.jpg";
import SubTab from "../SubTab/SubTab";
const ChungnamInnovationProject = () => {
  const category = "chungnamInnovationProject";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}기업 지원 사업
            </div>
            <div className="banner_main_text">기업 지원 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">기업지원사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div
              className="navi_box enterprise"
              onClick={() => handleSelect(2)}
            >
              <div className="navi_main_text">충남지역혁신프로젝트 사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  충남지역혁신프로젝트 사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationCluster")
                  }
                >
                  충남지역혁신클러스터
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/carbonNeutralInfrastructure")
                  }
                >
                  탄소중립실증인프라구축사업
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sub_main_area founded "> */}
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="project-img1"></div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="project_img_box">
                <div className="project-img2"></div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계</div>
              </div>
              <div className="project-img3"></div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업 목표</div>
              </div>
              <div className="objective_box_project">
                <div className="object-txt project">
                  <span>금속산업군의 성장기업을 발굴</span>하여 기술지원과
                  시제품 제작을 지원하고 탄소중립 실현을 위한 저탄소{" "}
                  <span>ISO 인증지원</span>을 통해 금속산업에서
                  첨단금속소재산업으로 빠르게 전환할 수 있도록 지원하며, 그에
                  따른 신규 <span>고용 창출과 기업의 경쟁력 강화</span>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">최종 성과</div>
              </div>
              <div className="final_result_box">
                <div className="project-result-box">
                  <table className="project-result-table">
                    <thead>
                      <tr>
                        <th>구분</th>
                        <th>취업</th>
                        <th>기타</th>
                        <th>세부 사업 내용</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          rowSpan={2}
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          2024년
                        </td>
                        <td rowSpan={2}>60명</td>
                        <td>기업지원(20개사)</td>
                        <td className="table-left">
                          · 첨단금속소재산업 시제품 제작지원 20개사/40명
                          고용창출
                          <br />
                          <span>- 초정밀 가공 및 금형, 핵심부품 가공</span>
                        </td>
                      </tr>
                      <tr>
                        <td>인증지원(20개사)</td>
                        <td className="table-left">
                          · 저탄소 관련 ISO 인증지원 20개사/20명 고용창출
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChungnamInnovationProject;
