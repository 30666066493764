import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/koreanQuickStartProgram_img.jpg";
import SubTab from "./../SubTab/SubTab";

const KoreanQuickStartProgram = () => {
  const category = "koreanQuickStartProgram";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}인재 양성 사업
            </div>
            <div className="banner_main_text">인재 양성 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">인재양성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div className="navi_box human" onClick={() => handleSelect(2)}>
              <div className="navi_main_text">한국형 퀵스타트프로그램 사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  지역산업맞춤형 인력양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/koreanQuickStartProgram")}
                >
                  한국형 퀵스타트프로그램 사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/smeResearchTalentSupport")}
                >
                  중소기업 연구인력 현장맞춤형 양성지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamIndustryAcademiaProgram")
                  }
                >
                  충남산학융합형 인력양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/youthVentureProgram")}
                >
                  청년도전지원사업
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="korea-img1"></div>
            <div className="korea-img2"></div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div style={{ padding: "20px 0 60px 0" }}>
                <div
                  className="korea-txt"
                  style={{ color: "#4faaa1", fontSize: "24px" }}
                >
                  사업장 증설하는 기업의{" "}
                  <span
                    className="korea-sub-txt"
                    style={{
                      color: "#4faaa1",
                      fontSize: "26px",
                      fontFamily: "Pretendard_b",
                    }}
                  >
                    조기 정착을 위해
                    <br />
                    지속적인 기업 맞춤형 교육 프로그램 개발 및 운영을 통한
                    취업률 향상
                  </span>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계</div>
              </div>
              <div className="propulsion_box">
                <div className="propulsion_text_content_box">
                  <div className="text_content_header">당진시</div>
                  <div className="text_content_body_box">
                    <div className="content_title korea">
                      사업총괄 및 성과관리
                    </div>
                    <div className="content_text korea">
                      지역 내 투자유치 기업지원 계획수립
                    </div>
                    <div className="content_text korea">
                      지역 내 지방투자기업 추천 및 사업 참여 홍보
                    </div>
                    <div className="content_text korea">
                      사업추진을 위한 대응 자금 지원
                    </div>
                    <div className="content_text korea">
                      운영현황 점검 및 성과관리
                    </div>
                  </div>
                </div>
                <div className="propulsion_text_content_box">
                  <div className="text_content_plus">+</div>
                  <div className="text_content_body_box">
                    <div className="content_title none"></div>
                  </div>
                </div>
                {/* <div className="content_icon"></div> */}
                <div className="propulsion_text_content_box">
                  <div className="text_content_header">참여기업</div>
                  <div className="text_content_body_box">
                    <div
                      className="content_title korea"
                      style={{ backgroundColor: "#bfcae8" }}
                    >
                      투자 완료에 따른 고용 창출
                    </div>
                    <div className="content_text korea">
                      기업 맞춤형 교육프로그램 공동개발 (충남산학융합원)
                    </div>
                    <div className="content_text korea">
                      공장 가공에 따른 신규인력 모집 및 프로그램 참여 지원
                    </div>
                    <div className="content_text korea">
                      정규직 채용 및 고용 유지 지원책 발굴
                    </div>
                  </div>
                </div>
                <div className="propulsion_text_content_box">
                  <div className="text_content_plus">+</div>
                  <div className="text_content_body_box">
                    <div className="content_title none"></div>
                  </div>
                </div>
                <div className="propulsion_text_content_box">
                  <div className="text_content_header">
                    충남
                    <br />
                    산학융합원
                  </div>
                  <div className="text_content_body_box">
                    <div
                      className="content_title korea"
                      style={{ backgroundColor: "#dee4f4" }}
                    >
                      교육프로그램 개발 및 사후관리
                    </div>
                    <div className="content_text korea">
                      지역 내 지방투자기업 대상 사업홍보
                    </div>
                    <div className="content_text korea">
                      참여기업 맞춤형 교육프로그램 개발
                    </div>
                    <div className="content_text korea">
                      교육프로그램 운영 및 현장 교육 지원
                    </div>
                    <div className="content_text korea">
                      고용 유지를 위한 지역 정착 프로그램 운영
                    </div>
                    <div className="content_text korea">
                      참여자 사후관리(만족도 조사)
                    </div>
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업 목표 및 최종 성과</div>
              </div>
              <div className="final_result_box">
                <div className="project-result-box">
                  <div className="table-container">
                    <table className="project-result-table">
                      <thead>
                        <tr>
                          <th style={{ width: "20%" }}>구분</th>
                          <th style={{ width: "calc((100% - 20%) / 5)" }}>
                            충남산학융합원
                          </th>
                          <th style={{ width: "calc((100% - 20%) / 5)" }}>
                            (주)한국내화
                          </th>
                          <th style={{ width: "calc((100% - 20%) / 5)" }}>
                            (주)삼우
                          </th>
                          <th style={{ width: "calc((100% - 20%) / 5)" }}>
                            (주)비츠로셀
                          </th>
                          <th style={{ width: "calc((100% - 20%) / 5)" }}>
                            합계
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>교육 신청자 수</td>
                          <td>23명</td>
                          <td>8명</td>
                          <td>8명</td>
                          <td>8명</td>
                          <td>23명</td>
                        </tr>
                        <tr>
                          <td>교육 수료자 수</td>
                          <td>20명</td>
                          <td>7명</td>
                          <td>7명</td>
                          <td>6명</td>
                          <td>20명</td>
                        </tr>
                        <tr>
                          <td>취업자 수</td>
                          <td>20명</td>
                          <td>7명</td>
                          <td>7명</td>
                          <td>6명</td>
                          <td>20명</td>
                        </tr>
                        <tr>
                          <td>교육생 만족도</td>
                          <td>80점</td>
                          <td className="slash"></td>
                          <td className="slash"></td>
                          <td className="slash"></td>
                          <td>80점</td>
                        </tr>
                        <tr>
                          <td>취업자 만족도</td>
                          <td>80점</td>
                          <td>80점</td>
                          <td>80점</td>
                          <td>80점</td>
                          <td>80점</td>
                        </tr>
                        <tr>
                          <td>참여기업 만족도</td>
                          <td>-</td>
                          <td>80점</td>
                          <td>80점</td>
                          <td>80점</td>
                          <td>80점</td>
                        </tr>
                        <tr>
                          <td>
                            고용 유지율
                            <br />
                            <p style={{ fontSize: "14px" }}>(입사 6개월 후)</p>
                          </td>
                          <td>80%</td>
                          <td>80%</td>
                          <td>80%</td>
                          <td>80%</td>
                          <td>80%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KoreanQuickStartProgram;
