import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/hopeReturnPackage_img.jpg";
import SubTab from "./../SubTab/SubTab";

const HopeReturnPackage = () => {
  const category = "hopeReturnPackage";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}창업 육성 사업
            </div>
            <div className="banner_main_text">창업 육성 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">창업육성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div className="navi_box founded" onClick={() => handleSelect(2)}>
              <div className="navi_main_text">희망리턴패키지 사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  당진시 창업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/hopeReturnPackage")}
                >
                  희망리턴패키지 사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/hongseongIssueWarehouse")}
                >
                  홍성군 잇슈창고운영사업
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="hope-img1"></div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="final_result_box">
                <div className="project-result-box">
                  <div className="table-container">
                    <table className="project-result-table">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>구분</th>
                          <th colSpan={2} style={{ width: "calc(70% / 3)" }}>
                            주기별 사업목표
                          </th>
                          <th style={{ width: "calc(100% / 3)" }}>
                            주요 사업내용 등
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan={7}>
                            2단계
                            <br />
                            (6~10월)
                          </td>
                          <td rowSpan={2}>기본 및 심화 교육</td>
                          <td>
                            [경영개선]
                            <br />
                            61업체
                          </td>
                          <td rowSpan={2} style={{ textAlign: "left" }}>
                            <div className="content_text_parent">
                              기본교육(비대면, 23시간)
                              <div className="content_text_child">
                                소진공 지식배움터 교육콘텐츠 활용(20시간)
                              </div>
                              <div className="content_text_child">
                                국고보조금법, e나라도움 교육(3시간)
                              </div>
                            </div>
                            <div className="content_text_parent">
                              심화교육(대면, 7시간)
                              <div className="content_text_child">
                                소진공 소상공인 전문기술교육기관 등 활용
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            [재창업]
                            <br />
                            41업체
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}>사업화 지원</td>
                          <td>
                            [경영개선]
                            <br />
                            61업체
                          </td>
                          <td rowSpan={2} style={{ textAlign: "left" }}>
                            <div className="content_text_parent">
                              (6월) 사업화 자금 교부
                            </div>
                            <div className="content_text_parent">
                              (6~11월) 사업화 지원 및 멘토링 지원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            [재창업]
                            <br />
                            41업체
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}>자율 프로그램 지원</td>
                          <td>
                            [경영개선]
                            <br />
                            61업체
                          </td>
                          <td rowSpan={2} style={{ textAlign: "left" }}>
                            <div className="content_text_parent">
                              (6~10월) 전문가 자문 지원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            [재창업]
                            <br />
                            41업체
                          </td>
                        </tr>
                        <tr>
                          <td>특화 프로그램</td>
                          <td>237업체</td>
                          <td rowSpan={2} style={{ textAlign: "left" }}>
                            <div className="content_text_parent">
                              (6~11월) 온ㆍ오프라인 판로, IP, 글로벌화, 스마트
                              선도산업 지원 등
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계</div>
              </div>
              <div className="propulsion_img_box">
                <div className="hope-img2"></div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업 목표</div>
              </div>
              <div className="objective_box">
                <div className="objective_title first center">
                  "충청남도 소상공인 재기도약 및 적시 지원을 통한 지역경제
                  활성화"
                </div>
                <div className="objective_content_box hope">
                  <div className="objective_content hope">
                    <div className="content_title first">
                      <span className="hope-txt1">실효적 지원</span>
                    </div>
                    <div className="content_text_box hope">
                      <div className="content_text hope">
                        •지원 성과 밀착 관리
                      </div>
                      <div className="content_text hope">
                        •연계지원(진흥원 등)
                      </div>
                      <div className="content_text hope">•후속 관리</div>
                    </div>
                  </div>
                  <div className="objective_content hope">
                    <div className="content_title second">
                      <span className="hope-txt2">밀착지원</span>
                    </div>
                    <div className="content_text_box hope">
                      <div className="content_text hope">
                        •상시 응답 지원체계
                      </div>
                      <div className="content_text hope">•권역별 순회 교육</div>
                    </div>
                  </div>
                  <div className="objective_content hope">
                    <div className="content_title third">
                      <span className="hope-txt3">관리 강화</span>
                    </div>
                    <div className="content_text_box hope">
                      <div className="content_text hope">•수시 집행 점검</div>
                      <div className="content_text hope">
                        •멘토 관리 체계 강화
                      </div>
                    </div>
                  </div>
                </div>
                <div className="objective_icon">
                  <i className="icon_arrow_1"></i>
                </div>
                <div className="objective_title second center">
                  <span className="hope-last-txt1">
                    소상공인 재기도약 지원을 위한
                  </span>
                </div>
                <div className="objective_title third center">
                  <span className="hope-last-txt2">
                    "2024년 희망리턴패키지 지원사업"
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HopeReturnPackage;
