import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/carbonNeutralInfrastructure_img.jpg";
import SubTab from "./../SubTab/SubTab";

const CarbonNeutralInfrastructure = () => {
  const category = "CarbonNeutralInfrastructure";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}기업 지원 사업
            </div>
            <div className="banner_main_text">기업 지원 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">기업지원사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div
              className="navi_box enterprise"
              onClick={() => handleSelect(2)}
            >
              <div className="navi_main_text">탄소중립실증인프라구축사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  충남지역혁신프로젝트 사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationCluster")
                  }
                >
                  충남지역혁신클러스터
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/carbonNeutralInfrastructure")
                  }
                >
                  탄소중립실증인프라구축사업
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sub_main_area founded "> */}
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="business_title">
              <div className="carbon-img1"></div>
            </div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="business_img_box">
                <div className="carbon-img2"></div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">주요 과업</div>
              </div>
              <div className="propulsion_img_box">
                <div className="carbon-work-wrap">
                  <div className="carbon-content-left">센터건축</div>
                  <div className="carbon-content-right">
                    CCU 기술실증 공정설비 및 시험·분석·평가 지원센터 건축
                  </div>
                </div>
                <div className="carbon-work-wrap">
                  <div className="carbon-content-left">포집설비</div>
                  <div className="carbon-content-right">
                    CO₂ 포집 기술 실증 공정설비 구축
                  </div>
                </div>
                <div className="carbon-work-wrap">
                  <div className="carbon-content-left">전환활용설비</div>
                  <div className="carbon-content-right">
                    CO₂ 생물·광물 전환 활용 기술실증 공정설비 구축
                  </div>
                </div>
                <div className="carbon-work-wrap">
                  <div className="carbon-content-left">분석장비</div>
                  <div className="carbon-content-right">
                    CCU 소재 및 제품 시험·분석·평가 장비 36종 구축
                  </div>
                </div>
                <div className="carbon-work-wrap">
                  <div className="carbon-content-left">
                    기업지원 및 인력양성
                  </div>
                  <div className="carbon-content-right">
                    CCU 기술개발 기업지원 및 인력양성
                  </div>
                </div>
                <div className="carbon-work-wrap">
                  <div className="carbon-content-left">기타 기업지원</div>
                  <div className="carbon-content-right">
                    CCU 활성화를 위한 정책개발, 방법론, 표준화 활동 추진
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계</div>
              </div>
              <div className="carbon_img_box">
                <div className="carbon-floor-wrap">
                  <div className="carbon-floor-left first">KCL</div>
                  <div className="carbon-floor-right first">
                    <p>
                      · (부지선정) 한국건설생활환경시험연구원 서산
                      기후환경실증센터 부지 내 건축부지 선정
                    </p>
                    <p>
                      · (건축설계) 탄소중립 실증 인프라 지원센터 기본설계
                      <br />
                      <span className="sub-txt-kcl">
                        - 건축 총 연면적:1,242m2 (380평), 지상 1층
                      </span>
                    </p>
                    <p>· (분석장비) 장비 10종 구축</p>
                    <p>· (표준활동) 표준화 동향보고서 작성</p>
                  </div>
                </div>
                <div className="carbon-floor-wrap">
                  <div className="carbon-floor-left second">KRITC</div>
                  <div className="carbon-floor-right second">
                    <p>· 탄소중립실증인프라구축사업 기술교류화운영 계획 수립</p>
                    <p>
                      · 산업배기가스 CO₂ 유무기 소재 기술 특허분석 과업 지시서
                      작성
                    </p>
                  </div>
                </div>
                <div className="carbon-floor-wrap">
                  <div className="carbon-floor-left third">
                    <span>
                      <span className="CTP">CTP</span>
                      충남테크노파크
                    </span>
                  </div>
                  <div className="carbon-floor-right third">
                    <p>
                      · 기업지원 프로그램에 대한 수요조사 및 분석
                      <br />
                      <span className="sub-txt-ctp">
                        (기술개발 활성화 지원, 공정전환 컨설팅 지원, 전과정
                        평가/환경성적표지인증)
                      </span>
                    </p>
                  </div>
                </div>
                <div className="carbon-floor-wrap">
                  <div className="carbon-floor-left fourth">
                    <span>
                      충남
                      <br />
                      산학융합원
                    </span>
                  </div>
                  <div className="carbon-floor-right fourth">
                    <p>· 전문가 네트워크(협의체) 운영</p>
                    <p>· 탄소중립 및 탄소포집활용 교육과정 개발</p>
                    <p>· 보고서 발간(온실가스 상쇄 방법론)</p>
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">최종 목표</div>
              </div>
              <div className="objective_box">
                <div className="objective_title first">
                  CO₂ 포집, CO₂ 생물·광물 전환활용 인프라 및
                  기업지원체계(소재·제품 개발 기술지원, 시험·분석·평가 지원
                  등)를 구축하여 CCU 기술 상용화 촉진
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarbonNeutralInfrastructure;
