import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/dangjinStartupSupport_img.jpg";
import SubTab from "./../SubTab/SubTab";

const DangjinStartupSupport = () => {
  const category = "dangjinStartupSupport";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}창업 육성 사업
            </div>
            <div className="banner_main_text">창업 육성 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">창업육성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div className="navi_box founded" onClick={() => handleSelect(2)}>
              <div className="navi_main_text">당진시 창업지원사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  당진시 창업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/hopeReturnPackage")}
                >
                  희망리턴패키지 사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/hongseongIssueWarehouse")}
                >
                  홍성군 잇슈창고운영사업
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="dangjin-img1"></div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="business_img_box">
                <div className="dangjin-img2"></div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계 및 목표</div>
              </div>
              <div className="propulsion_box">
                <div className="propulsion_content_box vision">
                  <div className="propulsion_content">
                    <div className="propulsion_content_title">비전</div>
                    <div className="propulsion_content_text_box">
                      <div className="content_text">
                        창업가의 도전과 혁신을 통한 가치 창출
                      </div>
                    </div>
                  </div>
                  <div className="propulsion_content">
                    <div className="propulsion_content_title">목표</div>
                    <div className="propulsion_content_text_box">
                      <div className="content_text">
                        창업가 혁신 창업 클러스터 조성
                      </div>
                    </div>
                  </div>
                </div>
                <div className="propulsion_table_box">
                  <div></div>
                </div>
                <div className="dangjin-img3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DangjinStartupSupport;
