import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/hongseongIssueWarehouse_img.jpg";
import SubTab from "./../SubTab/SubTab";

const HongseongIssueWarehouse = () => {
  const category = "hongseongIssueWarehouse";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}창업 육성 사업
            </div>
            <div className="banner_main_text">창업 육성 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">창업육성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div className="navi_box founded" onClick={() => handleSelect(2)}>
              <div className="navi_main_text">홍성군 잇슈창고운영사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  당진시 창업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/hopeReturnPackage")}
                >
                  희망리턴패키지 사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/hongseongIssueWarehouse")}
                >
                  홍성군 잇슈창고운영사업
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="hongsung-img1"></div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="business_img_box">
                <div className="hongsung-img2"></div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계</div>
              </div>
              <div className="propulsion_box">
                <div className="propulsion_div_content_box">
                  <div className="propulsion_div_content first">
                    <div className="content_top first">사업목표</div>
                    <div className="content_middle">
                      <div className="content_text_box">
                        <div className="content_text">
                          지역 청년 창업
                          <br />
                          인프라
                        </div>
                      </div>
                    </div>
                    <div className="content_bottom">
                      <div className="content_text_box">
                        <div className="content_text">청년 문화 활성화</div>
                      </div>
                    </div>
                  </div>
                  <div className="propulsion_div_content icon">
                    <div className="content_top_icon"></div>
                    <div className="content_middle_icon">
                      <div className="content_icon">
                        <i className="icon_arrow_hongsung"></i>
                      </div>
                    </div>
                    <div className="content_bottom_icon button_none">
                      <div className="content_icon">
                        <i className="icon_arrow_hongsung"></i>
                      </div>
                    </div>
                  </div>
                  <div className="propulsion_div_content second">
                    <div className="content_top second">추진전략</div>
                    <div className="content_middle">
                      <div className="content_text_box">
                        <div className="content_text">
                          홍성 청년 창업 플랫폼
                        </div>
                      </div>
                    </div>
                    <div className="content_bottom">
                      <div className="content_text_box">
                        <div className="content_text">
                          홍성 복합문화공간=
                          <br />
                          '잇슈창고'
                          <br />
                          (지역 연계 문화 행사)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="propulsion_div_content icon">
                    <div className="content_top_icon"></div>
                    <div className="content_middle_icon">
                      <div className="content_icon">
                        <i className="icon_arrow_hongsung"></i>
                      </div>
                    </div>
                    <div className="content_bottom_icon button_none">
                      <div className="content_icon">
                        <i className="icon_arrow_hongsung"></i>
                      </div>
                    </div>
                  </div>
                  <div className="propulsion_div_content third">
                    <div className="content_top third">세부계획</div>
                    <div className="content_middle">
                      <div className="content_text_box">
                        <div className="content_text">
                          •지역 산업 연계 청년 창업가 발굴
                        </div>
                        <div className="content_text">
                          •창업 인프라 및 BM 고도화 지원
                        </div>
                        <div className="content_text">•창업 네트워크 확보</div>
                      </div>
                    </div>
                    <div className="content_bottom">
                      <div className="content_text_box">
                        <div className="content_text">
                          •청년 창업가 네트워크 지원
                        </div>
                        <div className="content_text">
                          •관내외 협력 체계 구축
                        </div>
                        <div className="content_text">
                          •지역 문화 행사 콘텐츠 제공
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업목표</div>
              </div>
              <div className="business_inner_logo_box">
                <div className="logo_content hongseong">
                  <i className="logo_hongseong"></i>
                </div>
                <div className="logo_content main">
                  <i className="logo_main"></i>
                </div>
              </div>
              <div className="objective_box" style={{ padding: "0 0 40px" }}>
                <div
                  className="objective_content_box objective_content_mobile"
                  style={{ padding: "0" }}
                >
                  <div className="objective_img_content_box">
                    <div className="hongsung-img3"></div>
                  </div>
                  <div className="content_icon">
                    <i className="icon_arrow_3"></i>
                  </div>
                  <div className="objective_text_content_box">
                    <div className="content_text hongseong_txt">
                      홍성군 복합문화창업 공간 '잇슈창고'를 지역 대표 문화 &
                      청년 창업가 성장 공간 활성화
                    </div>
                    <div className="content_text hongseong_txt">
                      지역 산업 및 자원 활용 청년 창업가 발굴 및 성장 고도화
                      지원으로 지역경제 활성화 & 일자리 확대 기반 구축
                    </div>
                    <div className="content_text hongseong_txt">
                      지역 문화 단체 및 기관 협력 프로그램 개발로 홍성 주민
                      누구나 참여 가능한 마을 문화 발전 & 지역 관광 프로그램
                      개발
                    </div>
                    <div className="content_text hongseong_txt">
                      지역 산업(농수산물)-청년 창업 시제품 개발 및 제작-홍보
                      마켓 운영 및 관광 상품 개발 지원을 통한 6차 산업 청년 창업
                      활성 도시 기반 마련
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HongseongIssueWarehouse;
